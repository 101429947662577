@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange: rgb(239, 100, 33);
  --dark-orange: rgb(249, 110, 40);
}

@font-face {
  font-family: "Blippo";
  src: local("Blippo"), url("./assets/Blippo.woff") format("woff");
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafaff;
  font-weight: 300;
}

main#header {
  background: #151418;
  background-image: url("./assets/tlo_test.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 9rem;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

a.normal {
  text-decoration: underline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none; /* Remove default bullets */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul li::before {
  content: "\2022";
  color: var(--orange);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 0em;
}

p.justifyContent {
  text-align: justify !important;
}

img {
  max-width: 100%;
}

.btn {
  border: 2px solid var(--orange);
  padding: 10px 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
  width: fit-content;
}

.PrimaryBtn {
  background: var(--orange);
  color: #f6f6f6;
}

.PrimaryBtn:hover {
  background: var(--dark-orange);
}

.SecondarBtn:hover {
  background: rgb(57, 134, 250);
  color: #fff;
}

.btn2 {
  margin-right: 1rem;
}

.margin-top {
  margin-top: 2rem;
}

.react-images__navigation.react-images__navigation--isModal button {
  color: black;
}

.Container {
  width: 100%;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.AboutBio {
  max-width: 750px;
  margin: 0 auto;
  text-align: justify;
  line-height: 1.6;
}

.SectionTitle {
  font-size: 28px;
  color: var(--orange);
  font-weight: 700;
  margin-bottom: 3.5rem;
  padding-left: 2rem;
}
.ProjectWrapper {
  margin-top: 5rem;
}

/* ------------------------------------ Media for Container */
@media (min-width: 576px) {
  .Container {
    max-width: 540px;
  }
  .SectionTitle {
    padding-left: 0rem;
  }
}
@media (min-width: 768px) {
  .Container {
    max-width: 720px;
  }
  .ProjectWrapper {
    margin-top: 5rem;
  }
}
@media (min-width: 992px) {
  .Container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .Container {
    max-width: 1000px;
  }
}
